<template>
  <form @submit.prevent="updateDetail(deficiency,'preventative')">
    <div class="row justify-content-between">
      <div class="col-12 text-right" v-if="isAllowed('psc.main.deficiency.overview.pm.edit') && isOwnVessel('pscReport')">
        <template v-if="toEdit">
          <button class="e-btn e-btn-outlined-red ml-2" @click="cancelUpdate('preventative')" type="button">Cancel</button>
          <button class="e-btn e-btn-blue ml-2" type="submit" >Save</button>
        </template>
        <template v-else>
          <button class="e-btn e-btn-green ml-2 px-3" @click="editDetail" type="button">Edit Detail</button>
        </template>
      </div>
    </div>
    <table class="w-100">
      <tr>
        <td style="width: 40%;">Detail <sub class="lead e-text-red font-weight-bold">*</sub></td>
        <td>
          <vue-editor v-model="deficiency.preventative.detail" class="my-2" v-if="toEdit" :editor-toolbar="toolbar">
          </vue-editor>
          <div v-else class="pre-wrap-txt textarea-display-card my-2"
               v-html="deficiency.preventative.detail || ''"/>
        </td>
      </tr>
      <tr>
        <td>Related Equipment</td>
        <td>
          <input v-if="toEdit" v-model="deficiency.preventative.related_equipment" type="text" class="form-control form-control-sm mb-2" name="related-equipment" id="related-equipment">
          <span v-else>{{deficiency.preventative.related_equipment}}</span>
        </td>
      </tr>
      <tr>
        <td>Related Procedures</td>
        <td>
          <input v-if="toEdit" v-model="deficiency.preventative.related_procedures"
                 type="text"
                 class="form-control form-control-sm mb-2"
                 name="related-equipment" id="related-equipment">
          <span v-else>{{deficiency.preventative.related_procedures}}</span>
        </td>
      </tr>
      <tr>
        <td>Requiring New/Revised Risk Assessment</td>
        <td>
          <select name="risk-assessment" :disabled="!toEdit" v-model="deficiency.preventative.for_risk_assessment" class="form-control form-control-sm mb-2" id="risk-assessment">
            <option :value="0">NO</option>
            <option :value="1">YES</option>
          </select>
        </td>
      </tr>
      <tr>
        <td>Requiring Shore Training(This will prep the topics for TTOS)</td>
        <td>
          <select name="shore-training" :disabled="!toEdit" v-model="deficiency.preventative.for_shore_training" class="form-control form-control-sm mb-2" id="shore-assessment">
            <option :value="0">NO</option>
            <option :value="1">YES</option>
          </select>
        </td>
      </tr>
    </table>
  </form>
</template>

<script>
import {permissionMixin} from "@/mixins/permissionMixins";
import pscDeficiencyMixin from "../../../../mixins/PscDeficiencyMixin";
import ObjectMixin from "../../../../mixins/ObjectMixin";

export default {
  name: "PscDeficiencyPreventiveMeasure",
  props:{
    preventative:{
      type:Object,
      default:null
    }
  },
  mixins:[permissionMixin,pscDeficiencyMixin,ObjectMixin],
  data(){
    return {
      toolbar:[
        ['bold','italic','strike','underline'],
        [{'color':["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
            "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
            "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
            "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
            "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"]}]
      ]
    }
  },
  methods:{
  }

}
</script>

<style scoped>
::v-deep .pre-wrap-txt.textarea-display-card p {
  margin-bottom: 0 !important;
}
</style>
